import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"

class ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Sitemap"
          description="Explore our website to find out more of on what we offer at Tetley's Coaches - Leeds' oldest, established bus and coach operator."
        />
        <div className="block-services inner-body-bg">
          <div
            className="body gutter page-content-parent page-article"
            style={{ background: "white" }}
          >
            <div>
              <h1 className="page-content-heading">Sitemap</h1>
            </div>

            <div className="news-article-body">
              <ul className="sitemap">
                <li>
                  <Link to="/" title="Tetley's Coaches">
                    Home
                  </Link>
                </li>
                <li className="nobullet">
                  <ul>
                    <li>
                      <Link to="/coach-hire-bradford/">
                        Coach hire Bradford
                      </Link>
                    </li>
                    <li>
                      <Link to="/coach-hire-halifax/">Coach hire Halifax</Link>
                    </li>
                    <li>
                      <Link to="/coach-hire-harrogate/">
                        Coach hire Harrogate
                      </Link>
                    </li>
                    <li>
                      <Link to="/coach-hire-huddersfield/">
                        Coach hire Huddersfield
                      </Link>
                    </li>
                    <li>
                      <Link to="/coach-hire-wakefield/">
                        Coach hire Wakefield
                      </Link>
                    </li>
                    <li>
                      <Link to="/coach-hire-york/">Coach hire York</Link>
                    </li>
                  </ul>
                </li>
                {/* <li><Link to="/about-tetleys-coaches" title="About Tetley's Coaches">About</Link></li>
                <li className="nobullet">
                  <ul>
                    <li><Link to="/about-testimonials" title="Testimonials">Testimonials</Link></li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/news/" title="News from Tetley's Coaches">
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/fleet/" title="Fleet">
                    Fleet
                  </Link>
                </li>
                <li className="nobullet">
                  <ul>
                    <li>
                      <Link to="/fleet/minibus-hire/">16 seat minibus</Link>
                    </li>
                    <li>
                      <Link to="/fleet/32-35-seat-midi-bus/">
                        32-35 seat midi bus
                      </Link>
                    </li>
                    <li>
                      <Link to="/fleet/49-53-seat-coach/">
                        49-53 seat coach
                      </Link>
                    </li>
                    <li>
                      <Link to="/fleet/57-seat-school-bus/">
                        57 seat school bus
                      </Link>
                    </li>
                    <li>
                      <Link to="/fleet/double-decker/">
                        67/80 seat double decker
                      </Link>
                    </li>
                    <li>
                      <Link to="/fleet/70-seat-coach/">70 seat coach</Link>
                    </li>
                    <li>
                      <Link to="/fleet/disabled-access-coach/">
                        Disabled access coach
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/coach-hire-services/" title="Coach hire services">
                    Services
                  </Link>
                </li>
                <li className="nobullet">
                  <ul>
                    <li>
                      <Link
                        to="/staff-shuttle-capita-arlington-business-centre/"
                      >
                        Capita - Staff shuttle
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/staff-shuttle-forza-foods/"
                      >
                        Forza - Staff shuttle
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/airport-transfers/"
                        title="Coach Hire For Airport Transfers"
                      >
                        Airport Transfers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/club-pub-trips/"
                        title="Coach Hire For Club and Pub Trips"
                      >
                        Club and Pub Trips
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/coast-country-day-trips/"
                        title="Coach Hire For Coast and Country Day Trips"
                      >
                        Coast and Country Day Trips
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/community-groups/"
                        title="Coach Hire For Community Groups"
                      >
                        Community Groups
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/college-university-trips/"
                        title="Coach Hire For College and University Trips"
                      >
                        College and University Trips
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/contract-coach-hire/"
                        title="Coach Hire For Contract Work"
                      >
                        Contract Work
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/corporate-events-coach-hire/"
                        title="Coach Hire For Corporate and Events"
                      >
                        Corporate and Events
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/day-at-the-races/"
                        title="Coach Hire For A Day At The Races"
                      >
                        Day At The Races
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/wheelchair-disabled-access-coach-hire/"
                        title="Wheelchair friendly and disabled access coach hire"
                      >
                        Disabled access coach hire
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/funerals/"
                        title="Coach Hire For Funerals"
                      >
                        Funerals
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/group-tours/"
                        title="Coach Hire For Group Tours"
                      >
                        Group Tours
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/school-trips/"
                        title="Coach Hire For School Trips"
                      >
                        School Trips
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/sporting-events/"
                        title="Coach Hire For Sporting Events"
                      >
                        Sporting Events
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/theatre-concerts-cultural-events/"
                        title="Coach Hire For Theatre, Concerts &amp; Cultural Events"
                      >
                        Theatre, Concerts &amp; Cultural Events
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/theme-parks/"
                        title="Coach Hire For Theme Parks"
                      >
                        Theme Parks
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/coach-hire-services/weddings-engagements-parties-nights-out/"
                        title="Coach Hire For Weddings, Engagements, Parties and Nights Out"
                      >
                        Weddings, Engagements, Parties and Nights Out
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/school-services/" title="School services">
                    Schools
                  </Link>
                </li>
                <li className="nobullet">
                  <ul>
                    <li>
                      <Link
                        to="/heckmondwike-grammar-services/"
                        title="Heckmondwike Grammar School"
                      >
                        Heckmondwike Grammar School
                      </Link>
                    </li>
                    <li>
                      <Link to="/r54-service/" title="R54 service">
                        R54 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/r55-service/" title="R55 service">
                        R55 service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/rossett-late-bus-service/"
                        title="Rossett late bus service"
                      >
                        Rossett late bus service
                      </Link>
                    </li>
                    <li>
                      <Link to="/hgs1-service/" title="HGS1 service">
                        HGS1 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/hgs2-service/" title="HGS2 service">
                        HGS2 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/hgs11-service/" title="HGS11 service">
                        HGS11 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/hgs12-service/" title="HGS12 service">
                        HGS12 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/hgs13-service/" title="HGS13 service">
                        HGS13 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/cc1-service/" title="CC1 service">
                        CC1 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/cc2-service/" title="CC2 service">
                        CC2 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/by25-service/" title="BY25 service">
                        BY25 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/fa1-service/" title="FA1 service">
                        FA1 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/s62-service/" title="S62 service">
                        S62 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/bg1-service/" title="BG1 service">
                        BG1 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/bg2-service/" title="BG2 service">
                        BG2 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/bg3-service/" title="BG3 service">
                        BG3 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/lc1-service/" title="LC1 service">
                        LC1 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/lc2-service/" title="LC2 service">
                        LC2 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/lc3-service/" title="LC3 service">
                        LC3 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/bg2-brigshaw-garforth/" title="BG2 service">
                        BG2 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/br2-service/" title="BR2 service">
                        BR2 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/br11-service/" title="BR11 service">
                        BR11 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/br12-service/" title="BR12 service">
                        BR12 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/br13-service/" title="BR13 service">
                        BR13 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/g1-service/" title="G1 service">
                        G1 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/g2-service/" title="G2 service">
                        G2 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/526-service/" title="526 service">
                        526 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/s20-service/" title="S20 service">
                        S20 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/g3-service/" title="G3 service">
                        G3 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/g4-service/" title="G4 service">
                        G4 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/p53-service/" title="P53 service">
                        P53 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/p54-service/" title="P54 service">
                        P54 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/p89-service/" title="P89 service">
                        P89 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/574-service/" title="574 service">
                        574 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/575-service/" title="575 service">
                        575 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/l73-service/" title="L73 late bus service">
                        L73 late bus service
                      </Link>
                    </li>
                    <li>
                      <Link to="/r5-service/" title="R5 service">
                        R5 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/154-service/" title="154 service">
                        154 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/tn1-service/" title="TN1 service">
                        TN1 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/r10-service/" title="R10 service">
                        R10 service
                      </Link>
                    </li>
                    <li>
                      <Link to="/rossett-late-bus-service/" title="Rossett late bus service">
                        Rossett late bus service
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="/contact/" title="Contact Tetley's Coaches">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/jobs/" title="Jobs">
                    Jobs
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy/" title="Privacy policy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/policies-documents/" title="Document policy">
                    Policies &amp; Documents
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServicePage
